/***********************/
/*        Layout       */
/***********************/
/*app, .app {
  height: 100%;
  display: block;
}*/

@use "sass:math";

.chat-sidebar-container {
  color: $text-color;
}

.sidebar {
  position: fixed;
  z-index: 100;
  left: -$sidebar-width;
  top: 0;
  bottom: 0;
  width: $sidebar-width;
  background-color: $sidebar-bg-color;
  color: $sidebar-color;
  overflow: auto;
  @include transition(left $sidebar-transition-time ease-in-out);

  @include media-breakpoint-up(xl) {
    left: 0;
  }

  .slimScrollBar {
    @include border-radius(0 !important);
    background-color: $sidebar-color !important;
  }

  .sidebar-toggle {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 10px 15px 15px 15px;
  }
}

.nav-collapsed {
  .sidebar {
    left: 0;
  }
}

.page-controls, .content-wrap, .loader-wrap {
  left: 0;
  @include transition(left $sidebar-transition-time ease-in-out);

  @include media-breakpoint-up(xl) {
    margin-left: $sidebar-icon-state-width;
    padding-left: $sidebar-width - $sidebar-icon-state-width;
  }

  .pin-login-mode & {
    margin-left: 0;
    padding-left: 0;
  }
}

.page-controls {
  .dropdown-toggle-notifications {
    .img-circle {
      margin-top: -1px;
    }

    .circle {
      width: #{$line-height-base}rem;
      height: #{$line-height-base}rem;
      line-height: #{$line-height-base}rem;
    }
  }
}

.page-controls {
  @include transition(
                  left $sidebar-transition-time ease-in-out,
                  right $sidebar-transition-time ease-in-out
  );
  // right: -$sidebar-width;

  .nav-collapsed & {
    right: 0
  }

  .nav-static & {
    @include media-breakpoint-up(lg) {
      right: 0;
    }
  }
}

.chat-sidebar-opened .page-controls {

  // right: 254px;
  // left: -254px;

}

.page-controls {
  //position: absolute;
  z-index: 2;
  height: $navbar-height;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  @include media-breakpoint-down(sm) {
    font-size: 16px;

    .navbar-brand {
      position: absolute;
      left: 0;
      right: 0;
      font-weight: $font-weight-bold;
      text-align: center;

      > .fa-circle {
        font-size: 10px;
      }
    }

    .navbar-nav {
      position: relative;
      z-index: 1;
    }
  }

  .avatar {
    width: 30px;
    margin-top: -5px;

    &.pull-xs-left,
    &.pull-left {
      margin-right: 5px;
    }

    &.pull-xs-right,
    &.pull-right {
      margin-left: 5px;
    }
  }

  .navbar-nav > li > a {
    .rounded.rounded-lg,
    .circle.circle.lg {
      margin: -7px -7px -8px;
    }
  }

  .navbar-form.navbar-left {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
  }

  .navbar-form.navbar-left {
    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }

  .navbar-nav .fa-times {
    font-size: 20px;
    vertical-align: -2px;
  }

  .navbar-form {

    .input-group-addon {
      line-height: 1.5;

      .fa {
        vertical-align: -1px;
      }
    }

    .form-control {
      line-height: 1.7;
    }
  }
}

/* ff icons valign fix */
@-moz-document url-prefix() {
  .page-controls .navbar-nav > li > a > .rounded-lg > i {
    vertical-align: -8%;
  }
}

.content-wrap {
  position: relative;
  z-index: 0;
  //height: 100%;
}

.content {
  min-height: 100vh;
  height: 100vh;
  padding: $content-padding-top $content-padding-horizontal $content-padding-vertical;
  background-color: $body-bg;

  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 65px;
  }
}

.logo {
  margin: 11px 0 19px;
  height: $navbar-height;
  width: 100%;
  line-height: $navbar-height;
  text-align: center;
  vertical-align: middle;
  font-weight: $font-weight-bold;
  font-size: 18px;

  @include transition(width .3s ease-in-out);

  a {
    color: $white;
    text-decoration: none;
  }

  .nav-collapsed & {
    width: $sidebar-icon-state-width;
  }

  .nav-static & {
    @include media-breakpoint-up(lg) {
      width: 100%;
      @include transition(none);
    }
  }
}

.sidebar-nav {
  padding: 10px 0;
  font-size: 14px;

  &--bottom {
    /*position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;*/
  }

  @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
    font-size: 16px;
  }

  li.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  li a {
    display: block;
    color: $sidebar-color;
    text-decoration: none;

    .toggle {
      float: right;
      line-height: 18px;
      margin-right: $sidebar-padding-horizontal + $sidebar-slim-scroll-width;
      transition: transform .35s ease;
    }

    &.collapsed .toggle {
      transform: rotate(90deg);
    }

    .tag {
      float: right;
      line-height: 8px;
      margin-top: 7px;
      margin-right: $sidebar-padding-horizontal + $sidebar-slim-scroll-width;
      padding: 7px;
      border-radius: $border-radius-sm;

      @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
        margin-top: 11px;
      }
    }
  }

  > li > a {
    position: relative;
    border-top: 1px solid $sidebar-item-border-color;
    padding-left: 50px;
    line-height: 35px;

    &:hover {
      background-color: $sidebar-item-hover-bg-color;
    }

    @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
      line-height: 44px;
    }

    .icon {
      @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
        top: 8px;
      }

      display: block;
      position: absolute;
      top: 3px;
      left: $sidebar-padding-horizontal;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;

      .fa, .glyphicon {
        font-size: 16px;
        color: rgba($sidebar-color, 1);
      }

      .fa {
        position: relative;
        top: 1px;
      }

      .glyphicon {
        top: 6px;
      }

      .glyphicon-th {
        left: 0;
      }

      .glyphicon-tree-conifer {
        left: 0;
      }

      .glyphicon-map-marker {
        top: 6px;
      }

      .fa-envelope {
        top: 0;
      }

      @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
        .glyphicon {
          top: 6px;
        }

        .glyphicon-map-marker {
          top: 7px;
        }

        .fa {
          top: 0;
        }
      }

    }

    .toggle {
      line-height: 35px;

      @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
        line-height: 44px;
      }
    }

  }

  > .open > a {
    background-color: lighten($sidebar-item-hover-bg-color, 2%);
  }

  > li:last-child > a {
    border-bottom: 1px solid $sidebar-item-border-color;
  }

  > .active > a {
    color: $sidebar-item-active-color;
    background-color: $sidebar-bg-color;

    .icon {
      border-radius: 50%;
      background-color: $sidebar-item-active-color;

      .fa, .glyphicon {
        color: $sidebar-bg-color;
      }
    }
  }

  /*
   * Sub menus
   */

  > li ul {
    padding: 0;
    font-size: 13px;
    background-color: lighten($sidebar-bg-color, 5%);
    list-style: none;

    @media (min-width: map_get($grid-breakpoints, lg)) and (min-height: $screen-lg-height), (max-width: map_get($grid-breakpoints, md) - 1px) {
      font-size: 14px;
    }

    > li {
      > a {
        padding: 5px 0 5px 40px;

        &:hover {
          background-color: lighten($sidebar-item-hover-bg-color, 5%);
        }
      }

      &.active {
        > a {
          font-weight: normal;
          color: $white;
        }
      }

      /* third submenu */
      ul > li > a {
        padding-left: 55px;
      }
    }
  }

  /*
  First sub-menu
   */
  > li > ul {
    /* simulate padding */
    &:before {
      content: "";
      display: block;
      padding-top: math.div($line-height-computed,2);
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: math.div($line-height-computed,2);
    }
  }
}

.sidebar-nav-title {
  margin: 0 0 5px $sidebar-padding-horizontal;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;

  hr {
    background-color: #aaaaaa;
  }

  opacity: 1;
  @include transition(opacity $sidebar-transition-time ease-in-out);

  .action-link {
    color: $gray;
    float: right;
    margin-right: $sidebar-padding-horizontal + $sidebar-slim-scroll-width;
    margin-top: -1px;

    .fa, .glyphicon {
      font-size: 9px;
    }

    .glyphicon {
      top: 5px;
    }
  }

  .nav-static & {
    @include media-breakpoint-up(lg) {
      opacity: 1;
      @include transition(none);
    }
  }
}

.sidebar-status {
  position: relative;
  margin: 10px 0 20px;
  padding: 0 ($sidebar-padding-horizontal + $sidebar-slim-scroll-width) 0 $sidebar-padding-horizontal;

  > a {
    display: block;
    line-height: 40px;
    color: $gray-lighter;
    text-decoration: none;
  }

  .dropdown-menu {
    position: fixed;
    top: 57px;
    left: 0;
    margin: 0 20px;
    width: 320px;
    color: $text-color;
    @media (max-width: 380px) {
      margin: 0;
      width: 310px;
    }
  }

  /* fix notifications dropdown when there is no enough vertical space*/

  @media (max-height: 500px) {
    .dropdown-menu {
      bottom: 20px;
    }

    .notifications {
      .card-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      .list-group {
        position: absolute;
        top: 85px;
        left: 0;
        right: 0;
        bottom: 43px;
        height: auto;
      }

      .card-footer {
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
      }
    }
  }
}

.sidebar-labels {
  list-style: none;
  padding: $sidebar-padding-horizontal;
  padding-right: $sidebar-padding-horizontal + $sidebar-slim-scroll-width;
  font-size: $font-size-mini;

  > li {
    > a {
      color: $gray-lighter;
      text-decoration: none;
      font-weight: normal;

      .circle,
      .circle-o {
        position: relative;
        top: -2px;
        font-size: 9px;
        height: 12px;
        width: 12px;
        line-height: 12px;
        @include transition(margin-left $sidebar-transition-time ease-in-out);
      }

      .fa-circle {
        font-size: 11px;
        vertical-align: 1px;
        @include transition(margin-left $sidebar-transition-time ease-in-out);
      }

      .circle-o {
        top: 1px;
        left: 1px
      }

      .label-name {
        opacity: 1;
        @include transition(opacity $sidebar-transition-time ease-in-out);
      }

      .nav-collapsed & {
        .circle,
        .circle-o,
        .fa-circle {
          margin-left: 8px;
        }

        .label-name {
          opacity: 0;
        }
      }

      .nav-static & {
        @include media-breakpoint-up(lg) {
          .circle,
          .circle-o,
          .fa-circle {
            @include transition(none);
            margin-left: 0;
          }
          .label-name {
            @include transition(none);
            opacity: 1;
          }
        }
      }
    }

    + li {
      margin-top: math.div($line-height-computed,2);
    }
  }
}

.sidebar-alerts {
  font-size: $font-size-mini;
  @include transition(opacity $sidebar-transition-time ease-in-out);
  opacity: 1;

  .nav-collapsed & {
    opacity: 0;
  }

  .nav-static & {
    @include media-breakpoint-up(lg) {
      opacity: 1;
      @include transition(none);
    }
  }

  .alert {
    margin-bottom: 0;
    padding: math.div($line-height-computed,2) $sidebar-padding-horizontal;
    padding-right: $sidebar-padding-horizontal + $sidebar-slim-scroll-width;

    .close {
      font-size: 16px;
      text-shadow: none;
      opacity: 1;
      color: $sidebar-color;
    }
  }

  .progress {
    background-color: lighten($sidebar-bg-color, 10%);
  }
}

.chat-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -$chat-sidebar-width;
  width: $chat-sidebar-width;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  @include transition(right $sidebar-transition-time ease-in-out);
}

/* body tag is given this class */
.chat-sidebar-container {
  position: relative;

  &.chat-sidebar-opened {
    .chat-sidebar {
      right: 0;
    }
  }
}


.chat-sidebar-header {
  width: 100%;
  position: absolute;
  top: 0;
  padding: 10px;
  z-index: 3;
  background-color: $sidebar-bg-color;

  .form-control {
    padding: 0.6rem 0.85rem;
  }

  .chat-toggle {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 15px;
  }
}

.chat-sidebar-content {
  color: $sidebar-color;
}


.chat-sidebar-contacts {
  left: -100%;
  @include transition(left .4s ease-in-out);

  &.open {
    left: 0;
  }

  .sidebar-nav-title {
    color: $gray;
    margin-left: 20px;
    margin-right: 10px;

    &:first-child {
      margin-top: 0;
    }

    .nav-collapsed & {
      opacity: 1;
    }
  }
}

.chat-sidebar-chat {
  right: -100%;
  padding-bottom: 54px; /*footer height*/
  background-color: $sidebar-bg-color;
  @include transition(right .2s ease-in-out);

  &.open {
    right: 0;
  }

  .title {
    margin-bottom: 0;

    > a {
      display: block;
      padding: $padding-large-vertical $padding-large-horizontal;
      background-color: rgba(255, 255, 255, .1);
      color: $gray-lighter;
      text-decoration: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .message-list {
    list-style: none;
    padding-left: 0;
    padding-top: math.div($line-height-computed,2);
  }

  .message {
    padding: 10px 20px;
    @include clearfix();

    > .thumb-sm {
      float: left;
    }

    &.from-me > .thumb-sm {
      float: right;
    }
  }

}

.message-body {
  .chat-sidebar-chat & {
    position: relative;
    margin-left: 50px;
    padding: 10px;
    font-size: $font-size-mini;
    font-weight: $font-weight-normal;
    background-color: $gray-lighter;
    color: $text-color;
    border-radius: $border-radius;

    &:before {
      right: 100%;
      top: 8px;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      border: 10px solid rgba(0, 0, 0, 0);
      border-right-color: $gray-lighter;
    }
  }

  .chat-sidebar-chat .message.from-me & {
    margin-left: 0;
    margin-right: 50px;
    background-color: $brand-warning;
    color: $gray-dark;

    &:before {
      right: auto;
      left: 100%;
      border-right-color: rgba(0, 0, 0, 0);
      border-left-color: $brand-warning;
    }
  }
}

.chat-sidebar-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  margin-top: 56px;
  height: calc(100% - 56px);

  .char-sidebar-wrapper {
    height: calc(100% - 76px);
    overflow-x: hidden;
    overflow-y: auto;
  }

}

.chat-sidebar-title {
  margin: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: $font-weight-normal;
}

.chat-sidebar-user-group {

  &.sortbar {
    margin-top: 10px;

    .list-group-item {
      padding: 0 20px;
    }
  }

  .list-group-item {
    background-color: transparent;
    margin-right: -20px;
    margin-bottom: 0;
    padding: 10px 20px;
    border: 0;
    color: $gray-lighter;
    border-radius: 0;

    &.active {
      &,
      &:focus,
      &:hover {
        background-color: rgba(255, 255, 255, .1);
        color: $brand-warning;
      }

      .badge {
        background-color: $brand-danger;
        color: $white;
      }

      .message-sender {
        font-weight: $font-weight-semi-bold;
      }
    }

    .circle-o {
      margin-top: 13px;
      font-size: 8px;
    }

    .fa-circle {
      font-size: 11px;
      line-height: 37px;
    }

    .badge {
      margin-top: 9px;
      margin-right: 6px;
      padding: 3px 5px;
    }
  }

  .thumb .status,
  .thumb-sm .status {
    border-color: $sidebar-bg-color;
  }

  a.list-group-item:hover {
    color: $gray-lighter;
    background-color: rgba(0, 0, 0, .15);
  }

  .message-preview {
    margin: 0;
    color: $gray;
    font-weight: 500;
  }

  .message-sender {
    margin: 0 0 5px;
    color: $gray;
    font-weight: 700;
  }
}

.chat-sidebar-footer {
  position: absolute;
  bottom: 0;
  right: -100%;
  width: 100%;
  margin-bottom: 0;
  padding: 10px;
  background-color: #3a3a3a;

  @include transition(right .2s ease-in-out);

  &.open {
    right: 0;
  }
}

.chat-notification {
  position: absolute;
  right: 12px;
  top: 35px;
  z-index: 20;
  margin-top: 3px;
  padding: 5px 0;
  cursor: pointer;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 18px;
    width: 0;
    height: 0;
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid $gray-dark;
  }
}

.chat-notification-inner {
  min-width: 120px;
  padding: 8px;
  font-size: 12px;
  border-radius: $border-radius;
  text-decoration: none;
  background-color: $gray-dark;
  color: $white;

  .title {
    margin: 0;
    font-weight: $font-weight-semi-bold;
    line-height: 28px;
    font-size: $font-size-sm;

    .thumb-xs {
      vertical-align: -9px;
      margin-right: 5px;
    }
  }

  .text {
    margin-top: 5px;
    margin-bottom: 0;
    color: $gray-light;
  }
}

.chat-notification-sing {
  position: absolute;
  top: 16px;
  left: 10px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $brand-danger;
}

.page-title {
  margin: 0 0 calc(#{$line-height-computed} + 6px);
}

.notifications {
  @include media-breakpoint-up(md) {
    width: 333px;
  }

  .card-header {
    background-color: #fff;

    .btn-group-justified {
      display: flex;

      label {
        flex: 1;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

  }

  height: 100%;

  .list-group {
    height: 320px;
    overflow-y: scroll;
  }

  .list-group-item {
    @include transition(background-color .15s ease-in-out);

    &:hover {
      background-color: $list-group-hover-bg;

      .progress-bar {
        background-color: $white !important;
      }
    }

    .progress {
      @include transition(background-color .15s ease-in-out);
    }
  }

  a.list-group-item {
    text-decoration: none;
    color: $gray;
  }

  .btn-notifications-reload {
    color: $navbar-dashboard-link-color;
  }
}

/***********************/
/*        Loader       */
/***********************/

.loader-wrap {
  position: fixed;
  z-index: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: $body-bg;

  opacity: 1;
  transition: transform $sidebar-transition-time ease-in-out, left $sidebar-transition-time ease-in-out, opacity .2s ease-out, background-color .2s ease-out;

  &.hiding {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  .fa {
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 30px;
    margin-top: -15px;
    margin-left: -10px;
    font-size: 20px;
    vertical-align: middle;
  }

  .nav-static & {
    @include media-breakpoint-up(lg) {
      @include transition(opacity .2s ease-out, background-color .2s ease-out);
      transform: translate(0, 0);
      margin-left: $sidebar-width;
    }
  }
}

/***********************/
/*       WidgetDirective        */
/***********************/

.widget {
  position: relative;
  margin-bottom: $grid-gutter-width;
  padding: $widget-padding-vertical $widget-padding-horizontal;
  background: $widget-bg-color;
  border-radius: $border-radius-sm;

  > header {
    margin: (-$widget-padding-vertical) (-$widget-padding-horizontal);
    padding: $widget-padding-vertical $widget-padding-horizontal;

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    + .widget-body,
    + .widget-body.no-padding {
      margin-top: $widget-padding-vertical;
    }
  }

  > .widget-body.no-padding {
    margin: (-$widget-padding-vertical) (-$widget-padding-horizontal);

    + footer {
      margin-top: $widget-padding-vertical;
    }
  }

  > footer {
    margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
    padding: $widget-padding-vertical $widget-padding-horizontal;
  }

  &:hover .widget-controls-hover {
    opacity: 1;
  }

  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .spinner {
      position: absolute;
      top: 50%;
      width: 100%; //ie fix
      margin-top: -10px;
      font-size: 20px;
      text-align: center;
    }
  }

  .widget-top-overflow,
  .widget-middle-overflow {
    position: relative;
    margin: 0 (-$widget-padding-horizontal);

    > img {
      max-width: 100%;
    }
  }

  .widget-top-overflow {
    margin-top: (-$widget-padding-vertical);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;

    > img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    > .btn-toolbar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin-right: $widget-padding-horizontal;

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: 0;
      }
    }
  }

  .widget-table-overflow {
    margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);

    th:first-child,
    td:first-child {
      padding-left: $widget-padding-horizontal;
    }

    th:last-child,
    td:last-child {
      padding-right: $widget-padding-horizontal;
    }
  }
}

.widget-controls {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 14px;
  font-size: $font-size-sm;

  &.left {
    left: 0;
    right: auto;
  }

  &.widget-controls-hover {
    opacity: 0;
    @include transition(opacity .15s);
  }


  > a {
    padding: 1px 4px;
    border-radius: 4px;
    color: rgba($black, .4);
    @include transition(color .15s ease-in-out);

    &:hover {
      color: rgba($black, .1);
      text-decoration: none;
    }

    > .glyphicon {
      vertical-align: 0;
      font-size: 12px;
    }

    > .fa {
      vertical-align: 1px;
    }
  }

  > .btn-group {
    vertical-align: baseline;
  }
}

.widget-card {
  min-height: 135px;
}

.widget-icon {
  opacity: .5;
  font-size: 42px;
  height: 70px;

  .glyphicon {
    top: 5px;
  }
}

.widget-image {
  position: relative;
  overflow: hidden;
  margin: (-$widget-padding-vertical) (-$widget-padding-horizontal);
  border-radius: $border-radius;

  > img {
    max-width: 100%;
    border-radius: $border-radius $border-radius 0 0;
    transition: transform .15s ease;
  }

  &:hover > img {
    transform: scale(1.1, 1.1);
  }

  .title {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
  }

  .info {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
  }
}

.widget-footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.widget-sm {
  height: 230px;
}

.widget-md {
  height: 373px;
}

.windget-padding-md {
  padding: $widget-padding-vertical $widget-padding-horizontal;
}

.windget-padding-lg {
  padding: $widget-padding-vertical*2 $widget-padding-horizontal*2;
}

.widget-body-container {
  position: relative;
  height: 100%;
}

.widget-top-overflow,
.widget-middle-overflow {
  position: relative;
  margin: 0 (-$widget-padding-horizontal);

  > img {
    max-width: 100%;
  }
}

.widget-top-overflow {
  margin-top: (-$widget-padding-vertical);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;

  > img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > .btn-toolbar {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-right: $widget-padding-horizontal;

    @include media-breakpoint-up(md) {
      top: auto;
      bottom: 0;
    }
  }
}

/***********************************/
/**         Widgets Grid          **/
/***********************************/

.widget-container {
  min-height: 30px;

  .widget:not(.fullscreened) > header {
    cursor: move;
  }
}

.widget-placeholder {
  background: rgba($white, .4);
  border: 1px dashed $gray-light;
  margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px;
}

/***********************/
/*       Buttons       */
/***********************/

.btn-inverse {
  @include button-variant($white, $gray, darken($gray, 10%));
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.input-group {
  .input-group-addon {
    padding: .375rem .75rem .375rem .75rem;
  }
}

/***********************/
/*     Content Map     */
/***********************/

.content-map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  + .page-title {
    position: relative;
    z-index: 2;
  }
}

/***********************/
/*    Part:Stats Row   */
/***********************/

.stats-row {
  margin-bottom: 15px;
}

.stat-item {
  display: inline-block;
  padding-right: 15px;

  & + .stat-item {
    padding-left: 15px;
    border-left: 1px solid $hr-border-color;
  }

  .name {
    margin-bottom: 2px;
  }

  .value {
    font-weight: bold;
  }

  &.stat-item-mini-chart {
    position: relative;
    top: -12px;
    padding-left: 0;
    border-left: none;
  }
}

/***********************/
/*    Part:Select Box   */
/***********************/


/* Style when highlighting a search. */
.p-select-highlight {
  font-weight: bold;
}

.p-select-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}


.p-select-choices-row:hover {
  background-color: #f5f5f5;
}

/* Select2 theme */

/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice {
  border-color: #D44950;
}

.select2-result-single {
  padding-left: 0;
}

.select2-locked > .select2-search-choice-close {
  display: none;
}

.select-locked > .p-select-match-close {
  display: none;
}

body > .select2-container.open {
  z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}

/* Handle up direction Select2 */
.p-select-container[theme="select2"].direction-up .p-select-match {
  border-radius: 4px; /* FIXME hardcoded value :-/ */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.p-select-container[theme="select2"].direction-up .p-select-dropdown {
  border-radius: 4px; /* FIXME hardcoded value :-/ */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  border-top-width: 1px; /* FIXME hardcoded value :-/ */
  border-top-style: solid;

  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);

  margin-top: -4px; /* FIXME hardcoded value :-/ */
}

.p-select-container[theme="select2"].direction-up .p-select-dropdown .select2-search {
  margin-top: 4px; /* FIXME hardcoded value :-/ */
}

.p-select-container[theme="select2"].direction-up.select2-dropdown-open .p-select-match {
  border-bottom-color: #5897fb;
}

/* Selectize theme */

/* Helper class to show styles when focus */
.selectize-input.selectize-focus {
  border-color: #007FBB !important;
}

/* Fix input width for Selectize theme */
.selectize-control > .selectize-input > input {
  width: 100%;
}

/* Fix dropdown width for Selectize theme */
.selectize-control > .selectize-dropdown {
  width: 100%;
}

/* Mark invalid Selectize */
.ng-dirty.ng-invalid > div.selectize-input {
  border-color: #D44950;
}

/* Handle up direction Selectize */
.p-select-container[theme="selectize"].direction-up .p-select-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);

  margin-top: -2px; /* FIXME hardcoded value :-/ */
}

/* Bootstrap theme */

/* Helper class to show styles when focus */
.btn-default-focus {
  color: #333;
  background-color: #EBEBEB;
  border-color: #ADADAD;
  text-decoration: none;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.p-select-bootstrap .p-select-toggle {
  position: relative;
}

.p-select-bootstrap .p-select-toggle > .caret {
  position: absolute;
  height: 10px;
  top: 50%;
  right: 10px;
  margin-top: -2px;
}

/* Fix Bootstrap dropdown position when inside a input-group */
.input-group > .p-select-bootstrap.dropdown {
  /* Instead of relative */
  position: static;
}

.input-group > .p-select-bootstrap > input.p-select-search.form-control {
  border-radius: 4px; /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .p-select-bootstrap > input.p-select-search.form-control.direction-up {
  border-radius: 4px !important; /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.p-select-bootstrap > .p-select-match > .btn {
  /* Instead of center because of .btn */
  text-align: left !important;
}

.p-select-bootstrap > .p-select-match > .caret {
  position: absolute;
  top: 45%;
  right: 15px;
}

/*.p-disabled {
  background-color: #eceeef;
  border-radius: 4px;
  position: absolute;
  z-index: 5;
  opacity: 0.6;
  top: 0;
  left: 0;
  cursor: not-allowed;
}*/

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.p-select-bootstrap > .p-select-choices {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  margin-top: -1px;
}

body > .p-select-bootstrap.open {
  z-index: 1000; /* Standard Bootstrap dropdown z-index */
}

.p-select-multiple.p-select-bootstrap {
  height: auto;
  padding: 3px 3px 0 3px;
}

.p-select-multiple.p-select-bootstrap input.p-select-search {
  background-color: transparent !important; /* To prevent double background when disabled */
  border: none;
  outline: none;
  height: 1.666666em;
  margin-bottom: 3px;
}

.p-select-multiple.p-select-bootstrap .p-select-match .close {
  font-size: 1.6em;
  line-height: 0.75;
}

.p-select-multiple.p-select-bootstrap .p-select-match-item {
  outline: 0;
  margin: 0 3px 3px 0;
}

.p-select-multiple .p-select-match-item {
  position: relative;
}

.p-select-multiple .p-select-match-item.dropping-before:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  margin-right: 2px;
  border-left: 1px solid #428bca;
}

.p-select-multiple .p-select-match-item.dropping-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  margin-left: 2px;
  border-right: 1px solid #428bca;
}

.p-select-bootstrap .p-select-choices-row > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.p-select-bootstrap .p-select-choices-row > a:hover, .p-select-bootstrap .p-select-choices-row > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.p-select-bootstrap .p-select-choices-row.active > a {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca;
}

.p-select-bootstrap .p-select-choices-row.disabled > a,
.p-select-bootstrap .p-select-choices-row.active.disabled > a {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

/* fix hide/show angular animation */
.p-select-match.ng-hide-add,
.p-select-search.ng-hide-add {
  display: none !important;
}

/* Mark invalid Bootstrap */
.p-select-bootstrap.ng-dirty.ng-invalid > button.btn.p-select-match {
  border-color: #D44950;
}

/* Handle up direction Bootstrap */
.p-select-container[theme="bootstrap"].direction-up .p-select-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.navheadline {
  font-size: 2rem;
  margin: 10px 0 0 10px;
  display: inline-block;
  float: left;
}


.datepicker td.disabled.last + td {
  color: $white !important;
  background-color: red !important;
}

.sortbar {
  h4 {
    padding: 10px;
    margin-bottom: 0;
  }

  .list-group-item {
    padding-top: 0;
    color: $gray;
  }

  span {
    font-size: 12px;
    cursor: pointer;

    &.active {
      color: $brand-primary;
    }
  }
}

.sidebar-overlay {
  width: 100%;
  height: 100%;
  background-color: $gray;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  opacity: 0;
  display: none;
  @include transition(opacity 0.3s ease-in-out);

  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.app {
  &.nav-collapsed, &.chat-sidebar-opened {
    .sidebar-overlay {
      display: block;
      opacity: 0.4;
    }
  }
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  display: block;
  margin: 0 auto;
}


.scroll-indicator {
  position: absolute;
  top: 12px;
  right: 75px;
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.team-member-link {
  font-size: 12px;
  color: white;
  line-height: 1;
  margin-bottom: 12px;
}

//prime ng menu styles
.menu-root {
  display: none;
}

//flex form designs
.full-width {
  width: 100%!important;
}

.full-height {
  height: 100%!important;
}

.multi-field-wrapper {
  display: flex;
  align-items: baseline;

  .multi-field {
    .mat-form-field-infix {
      display: flex;

      /*input + span {
        position: relative;
        margin-right: 0.5rem;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: #ccc;
        }
      }*/
    }
    /*.mat-form-field-suffix {
      right: 0.5rem;
    }*/
  }

  .divider {
    position: relative;
    margin: 0 0.5rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #ccc;
    }
  }

  @for $i from 0 through 10 {
    .fg-#{$i} {
      flex-grow: $i;
    }
  }

  @for $i from 0 through 10 {
    .fs-#{$i} {
      flex-shrink: $i;
    }
  }

  @for $i from 0 through 20 {
    .width-#{$i * 5} {
      width: $i * 5%;
    }
  }

  @for $i from 0 through 20 {
    .flex-basis-#{$i * 5} {
      flex-basis: $i * 5%;
    }
  }
}

.overflow-auto {
  overflow: auto!important;
}
